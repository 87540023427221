<template>
  <div class="a400" v-if="!$store.state.loading">
    <div class="cover" data-aos="fade-up" data-aos-duration="1000">
      <img :src="images[0]" alt="CarboBrake A400"/>
    </div>
    <h1 data-aos="zoom-in" data-aos-duration="1000">
      {{ $t('a400-title') }}<span class="corsivo">{{ $t('a400-subtitle') }}</span>
    </h1>

    <div class="section">
      <div
          class="section-title side"
          data-aos="fade-right"
          data-aos-duration="1000"
          v-html="$t('a400-section1-title')"
      ></div>
      <div
          class="section-paragraph side"
          data-aos="fade-left"
          data-aos-duration="1000"
          v-html="$t('a400-section1-paragraph')"
      ></div>
    </div>

    <div class="section">
      <div
          class="section-title side"
          data-aos="fade-right"
          data-aos-duration="1000"
          v-html="$t('a400-section2-title')"
      ></div>
      <div class="section-paragraph side" data-aos="fade-left" data-aos-duration="1000">
        <table class="details">
          <tr>
            <td class="param-title">{{ $t('a400-section2-param1-title') }}</td>
            <td class="param-description">{{ $t('a400-section2-param1-description') }}</td>
          </tr>
          <tr>
            <td class="param-title">{{ $t('a400-section2-param2-title') }}</td>
            <td class="param-description">{{ $t('a400-section2-param2-description') }}</td>
          </tr>
          <tr>
            <td class="param-title">{{ $t('a400-section2-param3-title') }}</td>
            <td class="param-description">{{ $t('a400-section2-param3-description') }}</td>
          </tr>
          <tr>
            <td class="param-title">{{ $t('a400-section2-param4-title') }}</td>
            <td class="param-description">{{ $t('a400-section2-param4-description') }}</td>
          </tr>
        </table>
      </div>
    </div>

    <div class="section">
      <div class="section-image" data-aos="fade-up" data-aos-duration="1000">
        <img :src="images[1]" alt="CarboBrake Front"/>
      </div>
      <div
          class="section-title full"
          data-aos="fade-right"
          data-aos-duration="1000"
          v-html="$t('a400-section3-title')"
      ></div>
      <div
          class="section-paragraph full"
          data-aos="fade-left"
          data-aos-duration="1000"
          v-html="$t('a400-section3-paragraph')"
      ></div>
    </div>

    <div class="section">
      <div class="section-image" data-aos="fade-up" data-aos-duration="1000">
        <img :src="images[2]" alt="CarboBrake Cooling"/>
      </div>
      <div
          class="section-title full"
          data-aos="fade-right"
          data-aos-duration="1000"
          v-html="$t('a400-section4-title')"
      ></div>
      <div
          class="section-paragraph full"
          data-aos="fade-left"
          data-aos-duration="1000"
          v-html="$t('a400-section4-paragraph')"
      ></div>
    </div>

    <div class="section">
      <div class="products-showreel" data-aos="fade-up" data-aos-duration="1000">
        <h2>{{ $t('calipers-title') }}</h2>
        <p class="subtitle" v-html="$t('calipers-section1-title')"></p>
        <div id="thumb-container">
          <router-link to="/a370"
          ><div class="thumb a370">
            <img :src="images[3]" alt="CarboBrake A370"/>
            <h3>{{ $t('a370-title') }}</h3>
            <span class="corsivo">{{ $t('a370-subtitle') }}</span>
            <p v-html="$t('a370-section1-title')"></p>
            <button class="learn-more">{{ $t('btn-learn-more') }}</button>
          </div></router-link
          >

          <router-link to="/a410"
          ><div class="thumb a410">
            <img :src="images[4]" alt="CarboBrake A410"/>
            <h3>{{ $t('a410-title') }}</h3>
            <span class="corsivo">{{ $t('a410-subtitle') }}</span>
            <p v-html="$t('a410-section1-title')"></p>
            <button class="learn-more">{{ $t('btn-learn-more') }}</button>
          </div></router-link
          >
          <router-link to="/a420"
          ><div class="thumb a420">
            <img :src="images[5]" alt="CarboBrake A20"/>
            <h3>{{ $t('a420-title') }}</h3>
            <span class="corsivo">{{ $t('a420-subtitle') }}</span>
            <p v-html="$t('a420-section1-title')"></p>
            <button class="learn-more">{{ $t('btn-learn-more') }}</button>
          </div></router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      images: [
        require('../assets/400_cover.jpg'),
        require('../assets/400_front.jpg'),
        require('../assets/400_cooling.png'),
        require('../assets/370.png'),
        require('../assets/410.png'),
        require('../assets/420.png')
      ]
    }
  },
  mounted() {
    //LOAD ALL IMAGES IN PAGE
    this.$store.dispatch('LOAD_IMAGES', this.images).then(() => {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$store.commit('setFooterVisible')

          let event
          if (typeof Event === 'function') {
            event = new Event('resize')
          } else {
            //IE
            event = document.createEvent('Event')
            event.initEvent('resize', true, true)
          }
          window.dispatchEvent(event)
        }, 1100)
      })
    })
  }
}
</script>

<style lang="scss"></style>
